






































import { NftModel } from "@/models/nft-model";
import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Ref, Prop } from "vue-property-decorator";

@Observer
@Component({
  components: {
    CharacterIndex: () => import("@/components/character/character-index.vue"),
    CharacterAttribute: () =>
      import("@/components/character/character-attribute.vue"),
    CharacterAvatar: () =>
      import("@/components/character/character-avatar.vue"),
  },
})
export default class PreviewCharacter extends Vue {
  @Prop() character!: NftModel;
}
